<template>
  <div
    class="BaseInvoiceCreate column column--justify-between"
    data-cy="base-invoice-create"
  >
    <div class="BaseInvoiceCreate__top-wrapper width-100">
      <!-- User search + load # -->
      <div class="BaseInvoiceCreate__section-wrapper">
        <label class="BaseInvoiceCreate__section-lbl fs-14 fw-med">Load Details</label>
        <base-client-search
          v-if="!userIsClient"
          @selected="setClientInfo"
          :client-i-d-from-query="client.value"
          :disabled="disableSelectClient"
          :instructions="instructions.CLIENT_INSTRUCTION"
          :label="true"
          :label-for="'client'"
          ref="REF__BaseInvoiceCreate__ClientSearch"
          :valid="client.valid"
        >
          <template v-slot:label>
            CLIENT NAME
          </template>
        </base-client-search>
        <base-debtor-search
          @selected="setDebtorInfo"
          :debtor-i-d-from-query="debtor.value"
          :instructions="instructions.DEBTOR_INSTRUCTION"
          :label="true"
          :label-for="'debtor'"
          ref="REF__BaseInvoiceCreate__DebtorSearch"
          :valid="debtor.valid"
        >
          <template v-slot:label>
            DEBTOR NAME OR MC #
          </template>
        </base-debtor-search>
        <base-input
          v-model.trim="loadNumber.value"
          data-cy="base-invoice-create-load-number-input"
          :instructions="instructions.LOAD_NUMBER_INSTRUCTION"
          :label="true"
          :label-for="'load'"
          :valid="loadNumber.valid"
        >
          LOAD NUMBER
        </base-input>
        <base-textarea
          v-model="note"
          class="width-100"
          data-cy="base-invoice-create-note-textarea"
          :label="true"
          :label-for="'note'"
          :max-length="255"
          :placeholder="'Write a note here...'"
          :rows="'2'"
          spellcheck="true"
        >
          Note
        </base-textarea>
        <!-- TODO: update condition for displaying driver select -->
        <!-- TODO: fill with client's drivers -->
        <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2103 -->
        <!-- <div
          v-if="true"
          class="column mt-5"
        >
          <label class="fc-light fs-12 mb-7 uppercase">Driver</label>
          <v-select
            v-model="driver"
            class="width-100"
            :options="['Steve Rogers', 'Tony Stark', 'Ryan Reynolds']"
          />
        </div> -->
      </div>
      <!-- Values -->
      <div class="BaseInvoiceCreate__section-wrapper">
        <label class="BaseInvoiceCreate__section-lbl fs-14 fw-med">Amounts</label>
        <base-input
          v-model.trim="primaryRate.value"
          class="BaseInvoiceCreate__value-input"
          data-cy="base-invoice-create-primary-rate-input"
          :instructions="instructions.PRIMARY_RATE_INSTRUCTION"
          :label="true"
          :label-for="'primary-rate-value'"
          :placeholder="'$0'"
          :valid="primaryRate.valid"
        >
          PRIMARY RATE
        </base-input>
        <base-input
          v-model.trim="lumper.value"
          class="BaseInvoiceCreate__value-input"
          data-cy="base-invoice-create-lumper-input"
          :instructions="instructions.LUMPER_INSTRUCTION"
          :label="true"
          :label-for="'lumper-value'"
          :placeholder="'$0'"
          :valid="lumper.valid || !lumper.value"
        >
          LUMPER
        </base-input>
        <base-input
          v-model.trim="detention.value"
          class="BaseInvoiceCreate__value-input"
          data-cy="base-invoice-create-detention-input"
          :instructions="instructions.DETENTION_INSTRUCTION"
          :label="true"
          :label-for="'detention-value'"
          :placeholder="'$0'"
          :valid="detention.valid || !detention.value"
        >
          DETENTION
        </base-input>
        <base-input
          v-model.trim="advance.value"
          class="BaseInvoiceCreate__value-input"
          data-cy="base-invoice-create-advance-input"
          :instructions="instructions.ADVANCE_INSTRUCTION"
          :label="true"
          :label-for="'advance-value'"
          :placeholder="'$0'"
          :valid="advance.valid || !advance.value"
        >
          ADVANCE
        </base-input>
        <div class="Base BaseInvoiceCreate__total-wrapper column">
          <label class="fc-light fs-12 fw-med uppercase">total</label>
          <label
            class="fc-light fs-30"
            id="Testing__InvoiceTotal"
            data-cy="base-invoice-create-total-lbl"
          >
            {{ total }}
          </label>
        </div>
      </div>
      <!-- Documentation -->
      <div class="BaseInvoiceCreate__section-wrapper">
        <label class="BaseInvoiceCreate__section-lbl fs-14 fw-med">Documentation</label>
        <label class="BaseInvoiceCreate__upload-label fc-mid fs-15 mt-20">
          Please include rate confirmation, all BOL's, and lumper receipts
        </label>
        <base-button
          @click="uploadDocument"
          class="BaseInvoiceCreate__upload-btn bg-green fc-white"
          data-cy="base-invoice-create-upload-btn"
          ref="BaseInvoiceCreate__upload-btn"
        >
          Upload Document
        </base-button>
        <template v-if="[...documents.starting, ...documents.adding].length">
          <ul class="BaseInvoiceCreate__document-list">
            <base-uploaded-item
              v-for="(item, index) in documents.starting"
              :key="`starting-${index}`"
              @remove-upload="removeDocument(index, 'starting')"
              class="BaseInvoiceCreate__upload-item"
              :filename="item.name || item.filename"
              :index="index"
              :url="item.url"
            />
            <base-uploaded-item
              v-for="(item, index) in documents.adding"
              :key="`adding-${index}`"
              @remove-upload="removeDocument(index, 'adding')"
              class="BaseInvoiceCreate__upload-item"
              :filename="item.name || item.filename"
              :index="index"
              :url="item.url"
            />
          </ul>
        </template>
      </div>
    </div>

    <!-- CLIENT NOTE, BUYOUT, TRANSFER TOGGLE, BUTTONS -->
    <div class="BaseInvoiceCreate__footer row row--align-end row--justify-between">
      <div class="BaseInvoiceCreate__additional-options row row--align-end">
        <!-- Client Memo (clients only) -->
        <base-input
          v-if="userIsClient"
          v-model="memo"
          class="BaseInvoiceCreate__memo"
          data-cy="base-invoice-create-memo-input"
          :label="true"
          :label-for="'memo'"
          :max-length="40"
          :placeholder="'Write your memo here...'"
        >
          Memo
        </base-input>
        <!-- Buyout (employees only) + Transfer toggle -->
        <div
          v-if="!userIsClient && this.fromBuyout"
          class="row row--width-auto"
        >
          <base-checkbox
            v-model="buyout.isBuyout"
            class="mr-12"
            data-cy="base-invoice-create-buyout-checkbox"
            :disabled="editing || fromBuyout"
            :label-for="'is-buyout'"
            :starting-value="buyout.isBuyout"
          >
            Buyout
          </base-checkbox>
          <base-date-picker
            v-model="buyout.value"
            @input="$set(buyout, 'isBuyout', true)"
            :disabled="editing && !fromBuyout"
            :instruction="instructions.BUYOUT_DATE_INSTRUCTION"
            :placeholder="'Select a date'"
            :valid="buyout.valid"
            :value="buyout.value"
          />
        </div>
        <!-- Transfer toggle (only visible for non-buyout invoices) -->
        <base-toggle
          v-if="!fromBuyout && !buyout.isBuyout"
          @toggle="transferType = $event"
          :active-option="transferType"
          data-cy="base-invoice-create-transfer-toggle"
          :disabled="cannotSelectWire"
          :toggles="transferTypeOptions"
          id="Testing__TransferTypeToggle"
        />
      </div>
      <!-- Buttons (create vs edit) -->
      <div class="BaseInvoiceCreate__buttons row">
        <base-button
          v-if="!editing"
          @click="submitInvoice"
          class="BaseInvoiceCreate__buttons-submit bg-blue fc-white"
          data-cy="base-invoice-create-submit-btn"
          :disabled="disableSubmitBtn"
        >
          Submit
        </base-button>
        <base-button
          v-else
          @click="updateInvoice"
          class="BaseInvoiceCreate__buttons-update bg-blue fc-white"
          data-cy="base-invoice-create-update-btn"
          :disabled="disableSubmitBtn"
        >
          Update
        </base-button>
        <base-button
          v-if="!mobile.isMobile || editing"
          @click="$emit('cancel')"
          class="BaseInvoiceCreate__buttons-cancel "
          data-cy="base-invoice-create-cancel-btn"
        >
          Cancel
        </base-button>
      </div>
    </div>

    <!-- REDUCING SUPPORT: Let clients know the reprecussions of poor paperwork -->
    <div
      v-if="userIsClient"
      class="
        BaseInvoiceCreate__client-notification-footer bg-yellow row row--align-center row--justify-center
      "
    >
      <label class="fc-dark fs-15">
        Funding may be delayed for missing or unclear paperwork!
      </label>
    </div>

    <transition name="Transition__opacity-fade">
      <div
        v-if="possibleDuplicate"
        class="BaseInvoiceCreate__duplicate-modal row row--align-center row--justify-center"
      >
        <div class="BaseInvoiceCreate__duplicate-modal-bg" />
        <div class="BaseInvoiceCreate__duplicate-modal-content column">
          <label class="fs-20 fw-med mb-21">
            This invoice is a possible duplicate. Would you still like to submit it?
          </label>
          <div class="row mb-21">
            <base-button
              @click="submitInvoice"
              class="BaseInvoiceCreate__duplicate-modal-btn bg-blue fc-white mr-10"
              data-cy="base-invoice-create-duplicate-submit-btn"
              ref="BaseInvoiceCreate__duplicate-submit-btn"
              id="Testing__DuplicateInvoiceSubmitBtn"
            >
              Yes, Submit Possible Duplicate
            </base-button>
            <base-button
              @click="possibleDuplicate = false"
              class="BaseInvoiceCreate__duplicate-modal-btn bg-light fc-white"
              data-cy="base-invoice-create-duplicate-cancel-btn"
            >
              No
            </base-button>
          </div>
          <label class="fc-light">
            Selecting ‘Yes, Submit Possible Duplicate' will create an invoice that is flagged as a
            possible duplicate.
          </label>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Packages
import * as filestack from 'filestack-js'
import moment from 'moment-timezone'
// Helpers
import {
  Invoice,
  InvoiceDocument
} from '../utils/api'
import {
  transformStringNumberToNumber
} from '../utils/helpers'
import BobtailMath from '../utils/bobtailMath'
// Components
import BaseButton from './base-button.vue'
import BaseCheckbox from './base-checkbox.vue'
import BaseClientSearch from './base-client-search.vue'
import BaseDatePicker from './base-date-picker.vue'
import BaseDebtorSearch from './base-debtor-search.vue'
import BaseInput from './base-input.vue'
import BaseTextarea from './base-textarea.vue'
import BaseToggle from './base-toggle.vue'
import BaseUploadedItem from './base-uploaded-item.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

const achText = moment().tz('America/New_York').hour() < 11
  ? 'Same Day ACH'
  : 'Next Day ACH'

export default {
  name: 'BaseInvoiceCreate',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseClientSearch,
    BaseDatePicker,
    BaseDebtorSearch,
    BaseInput,
    BaseTextarea,
    BaseToggle,
    BaseUploadedItem
  },

  mixins: [ValidationMixin],

  props: {
    fromBuyout: {
      type: Boolean,
      required: false,
      default: false,
    },
    invoice: {
      type: Object,
      required: false,
      default: null
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  created () {
    // Alert the user that a wire cannot be selected at X time
    if (this.cannotSelectWire && !this.fromBuyout) {
      this.alertInfo(`From 4pm to 8pm on weekdays you must select ${achText}`)
    }

    // If new invoice and userIsClient
    if (this.userIsClient) {
      const client = JSON.parse(localStorage.getItem('user')).client
      this.setClientInfo(client)
    }

    if (this.invoice) {
      this.hydrate()
    }

    if (this.editing) {
      // You cannot edit the client for an approved or paid invoice
      if (['paid', 'approved'].includes(this.invoice.status)) {
        this.disableSelectClient = true
      }
    }
  },

  mounted () {
    if (typeof window === 'object') {
      window.addEventListener('keydown', this.autofill)
    }

    if (this.userIsClient) {
      this.$refs.REF__BaseInvoiceCreate__DebtorSearch.$el.children[1].focus()
    } else {
      this.$refs.REF__BaseInvoiceCreate__ClientSearch.$el.children[1].focus()
    }
  },

  beforeDestroy() {
    if (typeof window === 'object') {
      window.removeEventListener('keydown', this.autofill)
    }
  },

  data () {
    return {
      advance: {
        valid: true,
        value: '',
      },
      buyout: {
        isBuyout: false,
        valid: true,
        value: null,
      },
      client: {
        valid: true,
        value: null,
      },
      debtor: {
        valid: true,
        value: null,
      },
      debtor_selected_name: null,
      detention: {
        valid: true,
        value: '',
      },
      disableSelectClient: false,
      disableSubmitBtn: false,
      documents: {
        adding: [],
        starting: [],
        removing: [],
      },
      // TODO: https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2103
      // driver: null,
      editing: false,
      filestackAPIKey: 'ACrLnuwGBRv2dzbEzOiWQz',
      filestackClient: null,
      filestackPickerOptions: {
        accept: ['.pdf', '.jpg', '.jpeg', '.png'],
        allowManualRetry: true,
        // TODO: waiting on 'gmail' verification
        fromSources: ['local_file_system', 'googledrive', 'box'],
        maxFiles: 5,
      },
      loadNumber: {
        value: '',
        valid: true,
      },
      lumper: {
        valid: true,
        value: '',
      },
      note: '',
      possibleDuplicate: false,
      primaryRate: {
        valid: true,
        value: '',
      },
      memo: '',
      transferType: achText,
      transferTypeOptions: [achText, 'Wire ($15)'],
    }
  },

  watch: {
    invoice () {
      this.resetData()
      // do not hydrate if the invoice is null
      if (this.invoice) this.hydrate()
    }
  },

  computed: {
    cannotSelectWire() {
      return moment().tz('America/New_York').hour() >= 16
        && moment().tz('America/New_York').hour() < 20
    },

    mobile () {
      return this.$store.getters.mobile
    },

    total () {
      const total = new BobtailMath(this.primaryRate.value || 0)
        .add(this.detention.value || 0)
        .add(this.lumper.value || 0)
        .subtract(Math.abs(this.advance.value || 0))
        .dollars()
      if (isNaN(total)) return 'Invalid Input'
      return new Intl
        .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
        .format(total)
    },
  },

  methods: {
    autofill (e) {
      if (e.code === 'Backquote') {
        this.debtor_selected_name = 'Freds car shop'
        this.$set(this.loadNumber, 'value', '123456')
        this.$set(this.primaryRate, 'value', '125.50')
        this.$set(this.detention, 'value', '25.50')
        this.$set(this.lumper, 'value', 0)
        this.$set(this.advance, 'value', 0)
      }
    },

    hydrate () {
      // Originally, editing was true when hydrating (called in created() when an invoice is passed)

      // The "submit" and "update" buttons are controlled by editing's state, so we want
      // to manipulate editing even in hydrate for when we're autofilling a buyout item

      // In other words, if fromBuyout === true, set editing to the opposite or default to true
      // this.editing = this.fromBuyout ? !this.fromBuyout : true
      this.editing = !this.fromBuyout || this.invoice.id;

      this.$set(this.advance, 'value', this.invoice.amounts.advance.toString())
      this.$set(this.advance, 'valid', true)
      this.$set(this.buyout, 'isBuyout', this.invoice.is_buyout)
      this.$set(this.buyout, 'value', this.invoice.original_buyout_invoice_date)
      this.$set(this.buyout, 'valid', true)
      this.$set(this.client, 'value', this.invoice.client ? this.invoice.client.id : null)
      this.$set(this.debtor, 'value', this.invoice.debtor ? this.invoice.debtor.id : null)
      this.debtor_selected_name = this.invoice.debtor_selected_name
      this.$set(this.detention, 'value', this.invoice.amounts.detention.toString())
      this.$set(this.detention, 'valid', true)
      this.$set(
        this.documents, 'starting', this.invoice.individual_invoice_documents
          ? this.invoice.individual_invoice_documents.filter(invoice => invoice.name !== 'Bobtail Invoice.pdf')
          : []
      )
      // TODO: V2: https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2103
      // this.driver = this.invoice.driver
      this.$set(this.loadNumber, 'value', this.invoice.load_number)
      this.$set(this.loadNumber, 'valid', true)
      this.$set(this.lumper, 'value', this.invoice.amounts.lumper.toString())
      this.$set(this.lumper, 'valid', true)
      this.note = this.invoice.note
      this.$set(this.primaryRate, 'value', this.invoice.amounts.primary_rate.toString())
      this.$set(this.primaryRate, 'valid', true)
      this.memo = this.invoice.memo
      this.transferType = this.invoice.transfer_type === 'ach' ? achText : 'Wire ($15)'
    },

    moment,

    removeDocument (index, type) {
      const docToRemove = this.documents[type][index]
      // Only remove if we are editing an invoice and document already existed (documents.starting)
      if (this.editing && !this.documents.adding.includes(docToRemove)) {
        this.documents.removing.push(docToRemove)
      }
      // Finally, remove the doc from the list
      this.documents[type].splice(this.documents[type].indexOf(docToRemove), 1)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data())
      if (this.userIsClient) {
        const client = JSON.parse(localStorage.getItem('user')).client
        this.setClientInfo(client)
      }
    },

    setClientInfo (client) {
      if (client) {
        this.$set(this.client, 'value', client.id)
      } else {
        this.$set(this.client, 'value', null)
      }
    },

    setDebtorInfo (debtor) {
      if (debtor) {
        this.$set(this.debtor, 'value', debtor.id)
        this.debtor_selected_name = debtor.name
      } else {
        this.$set(this.debtor, 'value', null)
        this.debtor_selected_name = null
      }
    },

    async submitInvoice () {
      if (!this.validation()) return

      this.disableSubmitBtn = true

      this.progressStart()
      try {
        // Skip this section if
        if (!this.possibleDuplicate) {
          // Before submitting, check for duplicates
          const duplicates = (await Invoice.getPossibleDuplicates({
            client_id: this.client.value,
            debtor_id: this.debtor.value,
            load_number: this.loadNumber.value,
          })).data

          if (duplicates.length) {
            this.disableSubmitBtn = false
            this.possibleDuplicate = true
            this.$nextTick(() => {
              this.$refs['BaseInvoiceCreate__duplicate-submit-btn'].$el.focus()
            })
            this.progressFinish()
            return
          }
        }

        const invoice_documents = this.documents.adding.map(document => {
          return {
            name: document.filename,
            url: document.url,
          }
        });
        // Need the returned invoice
        const returnedInvoice = (await Invoice.create({
          advance: this.advance.value ? Math.abs(transformStringNumberToNumber(this.advance.value)) : 0,
          original_buyout_invoice_date: this.userIsClient ? null : this.buyout.value,
          client_id: this.client.value,
          memo: this.memo,
          debtor_id: this.debtor.value,
          // Always store debtor (legal) name in lowercase for full control over how to display
          // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639
          debtor_selected_name: this.debtor_selected_name,
          detention: this.detention.value ? transformStringNumberToNumber(this.detention.value) : 0,
          // TODO: https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2103
          // driver: this.driver,
          invoice_documents,
          is_adding_docs: this.documents.adding.length,
          is_buyout: this.userIsClient ? null : this.buyout.isBuyout,
          load_number: this.loadNumber.value,
          lumper: this.lumper.value ? transformStringNumberToNumber(this.lumper.value) : 0,
          note: this.note,
          primary_rate: transformStringNumberToNumber(this.primaryRate.value),
          status: 'pending',
          transfer_type: [achText.toLowerCase(), 'ach'].includes(this.transferType.toLowerCase()) ? 'ach' : 'wire',
        })).data
        this.$store.commit('INSERT_INVOICE', returnedInvoice)

        this.resetData()

        // Success Alert
        this.requestSuccess({ message: 'Invoice successfully created' })

        // Force search interval in pending-search to be restarted to avoid edge cases where
        // newly created invoice would disappear or never appear in UI due to pending-search
        // performing another search at the end of an interval
        if (this.userIsClient) this.$root.$emit('restart-search')
        // Return invoice for creating a pending invoice from a buyout item
        this.$emit('created', returnedInvoice)
      }
      catch (error) {
        this.captureSentryEvent(
          'Invoice Create "Submit" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an error creating your invoice' })
      }
      this.disableSubmitBtn = false
    },

    async submitInvoiceDocuments (invoice) {
      const newDocs = this.documents.adding.map(document => {
        // Format the documents to API spec
        return {
          invoice_id: invoice.id,
          name: document.filename,
          url: document.url,
        }
      })
      try {
        const promises = []
        newDocs.forEach(document => promises.push(
          InvoiceDocument.create({ ...document })
        ))
        this.documents.removing
          .forEach(document => promises.push(InvoiceDocument.delete(document.id)))
        await Promise.all(promises)
      }
      catch (error) {
        this.captureSentryEvent(
          'Invoice Create "Submit Docs" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue saving documents to the invoice' })
      }
    },

    async updateInvoice () {
      if (!this.validation()) return

      this.disableSubmitBtn = true
      this.progressStart()

      let notes = '';
      this.documents.adding.forEach(doc => {
        notes += `Added document ${doc.filename}. `
      })
      this.documents.removing.forEach(doc => {
        notes += `Removed document ${doc.filename || doc.name}. `
      })

      try {
        const updatedInvoice = (await Invoice.update({
          advance: this.advance.value
            ? Math.abs(transformStringNumberToNumber(this.advance.value))
            : 0,
          client_id: this.client.value,
          memo: this.memo,
          debtor_id: this.debtor.value,
          // Always store debtor (legal) name in lowercase for full control over how to display
          // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639
          debtor_selected_name: this.debtor_selected_name,
          detention: this.detention.value
            ? transformStringNumberToNumber(this.detention.value)
            : 0,
          // TODO: https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2103
          // driver: this.driver,
          id: this.invoice.id,
          is_adding_docs: this.documents.adding.length,
          load_number: this.loadNumber.value,
          lumper: this.lumper.value
            ? transformStringNumberToNumber(this.lumper.value)
            : 0,
          note: this.note,
          notes, // Used for updating invoice update with document changes
          primary_rate: transformStringNumberToNumber(this.primaryRate.value),
          transfer_type: [achText.toLowerCase(), 'ach'].includes(this.transferType.toLowerCase()) ? 'ach' : 'wire',
        })).data
        await this.submitInvoiceDocuments(updatedInvoice)
        this.disableSubmitBtn = false
        this.$store.commit('REPLACE_EXISTING_INVOICE', updatedInvoice)
        this.$store.commit('REPLACE_EXISTING_UNIVERSAL_SEARCH_INVOICE', updatedInvoice)
        this.requestSuccess({ message: 'Invoice successfully updated' })
        this.resetData()
        this.$emit('updated', updatedInvoice)

        // Force search interval in pending-search to be restarted to avoid edge cases where
        // newly created invoice would disappear or never appear in UI due to pending-search
        // performing another search at the end of an interval
        if (this.userIsClient) this.$root.$emit('restart-search')
      }
      catch (error) {
        this.captureSentryEvent(
          'InvoiceCreate "Update" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.disableSubmitBtn = false
        let message = 'There was an issue updating the invoice'
        if (error.response && error.response.data) {
          message = error.response.data.message
        }
        this.requestFailure({ message })
      }
    },

    uploadDocument () {
      this.filestackClient = filestack.init(this.filestackAPIKey)
      this.complete = false
      this.filestackClient.picker({
        ...this.filestackPickerOptions,
        onUploadDone: (res) => {
          res.filesUploaded.forEach(file => {
            console.log('file added:', file)
            this.documents.adding.push(file)
          })
          // focus upload button
          this.$refs['BaseInvoiceCreate__upload-btn'].$el.focus()
        }
      }).open()
    },

    validation () {
      let valid = true

      // Clients do not need to input a client.value
      if (!this.userIsClient) {
        if (!this.client.value) {
          this.$set(this.client, 'valid', false)
          valid = false
        } else {
          this.$set(this.client, 'valid', true)
        }
      }

      // If buyout is checked, a valid date must exist
      if (this.buyout.isBuyout && !this.isValidBuyoutDate(this.buyout.value)) {
        this.$set(this.buyout, 'valid', false)
        valid = false
      } else {
        this.$set(this.buyout, 'valid', true)
      }

      // There must always be a debtor
      if (!this.debtor.value) {
        this.$set(this.debtor, 'valid', false)
        valid = false
      } else {
        this.$set(this.debtor, 'valid', true)
      }

      if (!this.loadNumber.value || !this.isValidLoadNumber(this.loadNumber.value)) {
        this.$set(this.loadNumber, 'valid', false)
        valid = false
      } else {
        this.$set(this.loadNumber, 'valid', true)
      }

      if (!this.primaryRate.value || !this.isValidPrimaryRate(this.primaryRate.value)) {
        this.$set(this.primaryRate, 'valid', false)
        valid = false
      } else {
        this.$set(this.primaryRate, 'valid', true)
      }

      if (this.lumper.value && !this.isValidLumper(this.lumper.value)) {
        this.$set(this.lumper, 'valid', false)
        valid = false
      } else {
        this.$set(this.lumper, 'valid', true)
      }

      if (this.detention.value && !this.isValidDetention(this.detention.value)) {
        this.$set(this.detention, 'valid', false)
        valid = false
      } else {
        this.$set(this.detention, 'valid', true)
      }

      if (this.advance.value && !this.isValidAdvance(this.advance.value)) {
        this.$set(this.advance, 'valid', false)
        valid = false
      } else {
        this.$set(this.advance, 'valid', true)
      }

      return valid
    },
  },
}
</script>

<style lang="sass">
.BaseInvoiceCreate
  $block: &
  background-color: $white
  border-radius: $border-radius
  box-shadow: $container-shadow
  position: relative
  width: 100%

  .BaseInput,
  .BaseUserSearch
    margin-bottom: rem(5px)
    max-width: none
    width: 100%

    &:last-child
      margin-bottom: 0

  .BaseTextarea__input
    resize: none

  .BaseToggle
    margin-left: auto

  #is-buyout
    label
      color: $text-light
      // !important is required to overwrite .uppercase (#paul)
      text-transform: capitalize !important

  &__buttons
    margin-left: rem(10px)
    width: auto

  &__buttons-cancel
    box-shadow: none !important

  &__client-notification-footer
    border-radius: 0 0 $border-radius $border-radius
    padding: 0.5rem 1rem

  &__documents-lbl
    margin-bottom: rem(8px)

  &__document-list
    margin: 0
    padding: 0
    width: 100%

  &__duplicate-modal
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%
    z-index: 3

  &__duplicate-modal-bg
    background-color: $text-light
    border-radius: $border-radius
    height: 100%
    opacity: 0.5
    position: absolute
    width: 100%

  // &__duplicate-modal-btn

  &__duplicate-modal-content
    background-color: $white
    border-radius: $border-radius
    box-shadow: $modal-shadow
    max-width: rem(540px)
    padding: rem(31px) rem(39px)
    position: relative
    width: 100%
    z-index: 4

  &__footer
    border-top: rem(1px) solid #F3F3F6
    padding: rem(30px) rem(20px)

  &__section-wrapper
    +flex($direction: column, $grow: 1)
    padding-right: rem(18px)
    width: calc(100% / 3)

    &:last-child
      padding-right: 0

  &__section-lbl
    margin-bottom: rem(17px)

  &__memo.BaseInput
    margin-bottom: 0
    width: rem(250px)

  &__top-wrapper
    +flex(row, $wrap: wrap)
    padding: rem(14px) rem(20px) rem(23px)

  &__total-wrapper
    margin-top: rem(9px)

  &__transfer-wrapper
    position: relative

  &__upload-btn
    justify-content: center
    margin-bottom: rem(8px)
    margin-top: 1rem
    width: 100%

  &__upload-item
    margin-bottom: rem(9px)

    &:last-child
      margin-bottom: 0

  &__value-input
    .BaseInput
      &__input
        &::before
          content: '$'

  @media #{$mobile-large-landscape-and-down}
    &__buttons
      margin-left: 0
      margin-top: rem(10px)
      width: 100%

      // On mobile:
      // Submit isn't every displayed with another button, so full width
      // Update and cancel are always displayed together, so they should share the width
      #{$block}__buttons-cancel
        width: 50%

      #{$block}__buttons-submit
        width: 100%

      #{$block}__buttons-update
        width: 50%

    &__client-notification-footer
      border-radius: 0

    &__footer
      flex-direction: column

    &__section-wrapper
      padding-right: 0
      margin-top: rem(15px)
      width: 100%

      &:first-child
        margin-top: 0

    &__top-wrapper
      +flex(column)
      padding: rem(14px) 1rem rem(23px)

    &__transfer-wrapper
      margin-bottom: rem(10px)
      width: 100% // Makes the BaseToggle full-width

      .BaseToggle
        width: 100% // Makes BaseToggle full-width on mobile

    &__upload-label
      margin-top: 0

  @media (max-width: 530px)
    // On mobile: allow the additional options (buyout and transfer toggle) to take up 2 lines
    &__additional-options
      +flex(column, $wrap: wrap)

    &__memo.BaseInput
      width: 100%

    // On mobile: full-width the transfer toggle below the buyout and buyout date input
    #Testing__TransferTypeToggle
      margin-top: rem(10px)
      width: 100%

</style>