import { transformStringNumberToNumber } from './helpers'

/*
 * BobtailMath ALWAYS deals in pennies.
 * 1. It immediately converts all values to pennies, which means all values passed should be dollar values.
 *
 * If you want to return a dollar value, finish with .dollars()
 * If you want to return a penny value, finish with .pennies()
 */
export default class BobtailMath {
  constructor (zero) {
    // Converts a string representing a dollar value to a numerical penny value
    this.penniesWithoutRounding = (x) => transformStringNumberToNumber(x, { pennies: true, round: false })
    // Rounds a value to the nearest whole integer
    this.roundWithoutPennies = (x) => transformStringNumberToNumber(x, { pennies: false, round: true })
    // Default, starting value of any BobtailMath class
    this.zero = transformStringNumberToNumber(
      zero,
      {
        pennies: true,
        round: false,
      }
    )
  }

  add (x) {
    this.zero += this.penniesWithoutRounding(x)
    return this
  }

  console () {
    console.log(this.zero)
    return this
  }

  divide (x) {
    const pennies = this.penniesWithoutRounding(x)

    // JavaScript will return "Infinity" if you try to divide a number by null
    if (!pennies) {
      console.log('Value passed to divide method was null')
      return this
    }

    // Since we're performing the calculation as pennies, we need to multiply by 100 after
    this.zero = (this.zero / pennies) * 100

    return this
  }

  // Output dollars
  dollars () {
    if (!this.zero && this.zero !== 0) {
      console.log('this.zero was null; cannot output dollars from nothing')
      return this
    }

    // We need to round zero before submitting it to .toFixed, because .toFixed(2)
    // does not always round accurately (up or down). For example, 1.555.
    // toFixed(2) rounds down to 1.55 not 1.56, which is what we do not want
    this.zero = Math.round(this.zero) / 100

    return this.zero
  }

  multiply (x) {
    const pennies = this.penniesWithoutRounding(x)

    // JavaScript treats null as 0, so any number multiplied by null === 0
    if (!pennies) {
      console.log('Value passed to multiply method was null')
      return this
    }

    // Since we're performing the calculation as pennies, we need to divide by 100 after
    this.zero = (this.zero * pennies) / 100

    return this
  }

  // Output pennies
  pennies () {
    if (!this.zero && this.zero !== 0) {
      console.log('this.zero was null; cannot output dollars from nothing')
      return this
    }

    return this.roundWithoutPennies(this.zero)
  }

  subtract (x) {
    this.zero -= this.penniesWithoutRounding(x)
    return this
  }
}