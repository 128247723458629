var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "BaseInvoiceCreate column column--justify-between",
      attrs: { "data-cy": "base-invoice-create" }
    },
    [
      _c("div", { staticClass: "BaseInvoiceCreate__top-wrapper width-100" }, [
        _c(
          "div",
          { staticClass: "BaseInvoiceCreate__section-wrapper" },
          [
            _c(
              "label",
              { staticClass: "BaseInvoiceCreate__section-lbl fs-14 fw-med" },
              [_vm._v("Load Details")]
            ),
            !_vm.userIsClient
              ? _c("base-client-search", {
                  ref: "REF__BaseInvoiceCreate__ClientSearch",
                  attrs: {
                    "client-i-d-from-query": _vm.client.value,
                    disabled: _vm.disableSelectClient,
                    instructions: _vm.instructions.CLIENT_INSTRUCTION,
                    label: true,
                    "label-for": "client",
                    valid: _vm.client.valid
                  },
                  on: { selected: _vm.setClientInfo },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [_vm._v("\n          CLIENT NAME\n        ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    37517829
                  )
                })
              : _vm._e(),
            _c("base-debtor-search", {
              ref: "REF__BaseInvoiceCreate__DebtorSearch",
              attrs: {
                "debtor-i-d-from-query": _vm.debtor.value,
                instructions: _vm.instructions.DEBTOR_INSTRUCTION,
                label: true,
                "label-for": "debtor",
                valid: _vm.debtor.valid
              },
              on: { selected: _vm.setDebtorInfo },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function() {
                    return [_vm._v("\n          DEBTOR NAME OR MC #\n        ")]
                  },
                  proxy: true
                }
              ])
            }),
            _c(
              "base-input",
              {
                attrs: {
                  "data-cy": "base-invoice-create-load-number-input",
                  instructions: _vm.instructions.LOAD_NUMBER_INSTRUCTION,
                  label: true,
                  "label-for": "load",
                  valid: _vm.loadNumber.valid
                },
                model: {
                  value: _vm.loadNumber.value,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.loadNumber,
                      "value",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "loadNumber.value"
                }
              },
              [_vm._v("\n        LOAD NUMBER\n      ")]
            ),
            _c(
              "base-textarea",
              {
                staticClass: "width-100",
                attrs: {
                  "data-cy": "base-invoice-create-note-textarea",
                  label: true,
                  "label-for": "note",
                  "max-length": 255,
                  placeholder: "Write a note here...",
                  rows: "2",
                  spellcheck: "true"
                },
                model: {
                  value: _vm.note,
                  callback: function($$v) {
                    _vm.note = $$v
                  },
                  expression: "note"
                }
              },
              [_vm._v("\n        Note\n      ")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "BaseInvoiceCreate__section-wrapper" },
          [
            _c(
              "label",
              { staticClass: "BaseInvoiceCreate__section-lbl fs-14 fw-med" },
              [_vm._v("Amounts")]
            ),
            _c(
              "base-input",
              {
                staticClass: "BaseInvoiceCreate__value-input",
                attrs: {
                  "data-cy": "base-invoice-create-primary-rate-input",
                  instructions: _vm.instructions.PRIMARY_RATE_INSTRUCTION,
                  label: true,
                  "label-for": "primary-rate-value",
                  placeholder: "$0",
                  valid: _vm.primaryRate.valid
                },
                model: {
                  value: _vm.primaryRate.value,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.primaryRate,
                      "value",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "primaryRate.value"
                }
              },
              [_vm._v("\n        PRIMARY RATE\n      ")]
            ),
            _c(
              "base-input",
              {
                staticClass: "BaseInvoiceCreate__value-input",
                attrs: {
                  "data-cy": "base-invoice-create-lumper-input",
                  instructions: _vm.instructions.LUMPER_INSTRUCTION,
                  label: true,
                  "label-for": "lumper-value",
                  placeholder: "$0",
                  valid: _vm.lumper.valid || !_vm.lumper.value
                },
                model: {
                  value: _vm.lumper.value,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.lumper,
                      "value",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "lumper.value"
                }
              },
              [_vm._v("\n        LUMPER\n      ")]
            ),
            _c(
              "base-input",
              {
                staticClass: "BaseInvoiceCreate__value-input",
                attrs: {
                  "data-cy": "base-invoice-create-detention-input",
                  instructions: _vm.instructions.DETENTION_INSTRUCTION,
                  label: true,
                  "label-for": "detention-value",
                  placeholder: "$0",
                  valid: _vm.detention.valid || !_vm.detention.value
                },
                model: {
                  value: _vm.detention.value,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.detention,
                      "value",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "detention.value"
                }
              },
              [_vm._v("\n        DETENTION\n      ")]
            ),
            _c(
              "base-input",
              {
                staticClass: "BaseInvoiceCreate__value-input",
                attrs: {
                  "data-cy": "base-invoice-create-advance-input",
                  instructions: _vm.instructions.ADVANCE_INSTRUCTION,
                  label: true,
                  "label-for": "advance-value",
                  placeholder: "$0",
                  valid: _vm.advance.valid || !_vm.advance.value
                },
                model: {
                  value: _vm.advance.value,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.advance,
                      "value",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "advance.value"
                }
              },
              [_vm._v("\n        ADVANCE\n      ")]
            ),
            _c(
              "div",
              { staticClass: "Base BaseInvoiceCreate__total-wrapper column" },
              [
                _c(
                  "label",
                  { staticClass: "fc-light fs-12 fw-med uppercase" },
                  [_vm._v("total")]
                ),
                _c(
                  "label",
                  {
                    staticClass: "fc-light fs-30",
                    attrs: {
                      id: "Testing__InvoiceTotal",
                      "data-cy": "base-invoice-create-total-lbl"
                    }
                  },
                  [_vm._v("\n          " + _vm._s(_vm.total) + "\n        ")]
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "BaseInvoiceCreate__section-wrapper" },
          [
            _c(
              "label",
              { staticClass: "BaseInvoiceCreate__section-lbl fs-14 fw-med" },
              [_vm._v("Documentation")]
            ),
            _c(
              "label",
              {
                staticClass:
                  "BaseInvoiceCreate__upload-label fc-mid fs-15 mt-20"
              },
              [
                _vm._v(
                  "\n        Please include rate confirmation, all BOL's, and lumper receipts\n      "
                )
              ]
            ),
            _c(
              "base-button",
              {
                ref: "BaseInvoiceCreate__upload-btn",
                staticClass: "BaseInvoiceCreate__upload-btn bg-green fc-white",
                attrs: { "data-cy": "base-invoice-create-upload-btn" },
                on: { click: _vm.uploadDocument }
              },
              [_vm._v("\n        Upload Document\n      ")]
            ),
            _vm.documents.starting.concat(_vm.documents.adding).length
              ? [
                  _c(
                    "ul",
                    { staticClass: "BaseInvoiceCreate__document-list" },
                    [
                      _vm._l(_vm.documents.starting, function(item, index) {
                        return _c("base-uploaded-item", {
                          key: "starting-" + index,
                          staticClass: "BaseInvoiceCreate__upload-item",
                          attrs: {
                            filename: item.name || item.filename,
                            index: index,
                            url: item.url
                          },
                          on: {
                            "remove-upload": function($event) {
                              return _vm.removeDocument(index, "starting")
                            }
                          }
                        })
                      }),
                      _vm._l(_vm.documents.adding, function(item, index) {
                        return _c("base-uploaded-item", {
                          key: "adding-" + index,
                          staticClass: "BaseInvoiceCreate__upload-item",
                          attrs: {
                            filename: item.name || item.filename,
                            index: index,
                            url: item.url
                          },
                          on: {
                            "remove-upload": function($event) {
                              return _vm.removeDocument(index, "adding")
                            }
                          }
                        })
                      })
                    ],
                    2
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _c(
        "div",
        {
          staticClass:
            "BaseInvoiceCreate__footer row row--align-end row--justify-between"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "BaseInvoiceCreate__additional-options row row--align-end"
            },
            [
              _vm.userIsClient
                ? _c(
                    "base-input",
                    {
                      staticClass: "BaseInvoiceCreate__memo",
                      attrs: {
                        "data-cy": "base-invoice-create-memo-input",
                        label: true,
                        "label-for": "memo",
                        "max-length": 40,
                        placeholder: "Write your memo here..."
                      },
                      model: {
                        value: _vm.memo,
                        callback: function($$v) {
                          _vm.memo = $$v
                        },
                        expression: "memo"
                      }
                    },
                    [_vm._v("\n        Memo\n      ")]
                  )
                : _vm._e(),
              !_vm.userIsClient && this.fromBuyout
                ? _c(
                    "div",
                    { staticClass: "row row--width-auto" },
                    [
                      _c(
                        "base-checkbox",
                        {
                          staticClass: "mr-12",
                          attrs: {
                            "data-cy": "base-invoice-create-buyout-checkbox",
                            disabled: _vm.editing || _vm.fromBuyout,
                            "label-for": "is-buyout",
                            "starting-value": _vm.buyout.isBuyout
                          },
                          model: {
                            value: _vm.buyout.isBuyout,
                            callback: function($$v) {
                              _vm.$set(_vm.buyout, "isBuyout", $$v)
                            },
                            expression: "buyout.isBuyout"
                          }
                        },
                        [_vm._v("\n          Buyout\n        ")]
                      ),
                      _c("base-date-picker", {
                        attrs: {
                          disabled: _vm.editing && !_vm.fromBuyout,
                          instruction: _vm.instructions.BUYOUT_DATE_INSTRUCTION,
                          placeholder: "Select a date",
                          valid: _vm.buyout.valid,
                          value: _vm.buyout.value
                        },
                        on: {
                          input: function($event) {
                            return _vm.$set(_vm.buyout, "isBuyout", true)
                          }
                        },
                        model: {
                          value: _vm.buyout.value,
                          callback: function($$v) {
                            _vm.$set(_vm.buyout, "value", $$v)
                          },
                          expression: "buyout.value"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.fromBuyout && !_vm.buyout.isBuyout
                ? _c("base-toggle", {
                    attrs: {
                      "active-option": _vm.transferType,
                      "data-cy": "base-invoice-create-transfer-toggle",
                      disabled: _vm.cannotSelectWire,
                      toggles: _vm.transferTypeOptions,
                      id: "Testing__TransferTypeToggle"
                    },
                    on: {
                      toggle: function($event) {
                        _vm.transferType = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "BaseInvoiceCreate__buttons row" },
            [
              !_vm.editing
                ? _c(
                    "base-button",
                    {
                      staticClass:
                        "BaseInvoiceCreate__buttons-submit bg-blue fc-white",
                      attrs: {
                        "data-cy": "base-invoice-create-submit-btn",
                        disabled: _vm.disableSubmitBtn
                      },
                      on: { click: _vm.submitInvoice }
                    },
                    [_vm._v("\n        Submit\n      ")]
                  )
                : _c(
                    "base-button",
                    {
                      staticClass:
                        "BaseInvoiceCreate__buttons-update bg-blue fc-white",
                      attrs: {
                        "data-cy": "base-invoice-create-update-btn",
                        disabled: _vm.disableSubmitBtn
                      },
                      on: { click: _vm.updateInvoice }
                    },
                    [_vm._v("\n        Update\n      ")]
                  ),
              !_vm.mobile.isMobile || _vm.editing
                ? _c(
                    "base-button",
                    {
                      staticClass: "BaseInvoiceCreate__buttons-cancel ",
                      attrs: { "data-cy": "base-invoice-create-cancel-btn" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm.userIsClient
        ? _c(
            "div",
            {
              staticClass:
                "\n      BaseInvoiceCreate__client-notification-footer bg-yellow row row--align-center row--justify-center\n    "
            },
            [
              _c("label", { staticClass: "fc-dark fs-15" }, [
                _vm._v(
                  "\n      Funding may be delayed for missing or unclear paperwork!\n    "
                )
              ])
            ]
          )
        : _vm._e(),
      _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
        _vm.possibleDuplicate
          ? _c(
              "div",
              {
                staticClass:
                  "BaseInvoiceCreate__duplicate-modal row row--align-center row--justify-center"
              },
              [
                _c("div", {
                  staticClass: "BaseInvoiceCreate__duplicate-modal-bg"
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "BaseInvoiceCreate__duplicate-modal-content column"
                  },
                  [
                    _c("label", { staticClass: "fs-20 fw-med mb-21" }, [
                      _vm._v(
                        "\n          This invoice is a possible duplicate. Would you still like to submit it?\n        "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "row mb-21" },
                      [
                        _c(
                          "base-button",
                          {
                            ref: "BaseInvoiceCreate__duplicate-submit-btn",
                            staticClass:
                              "BaseInvoiceCreate__duplicate-modal-btn bg-blue fc-white mr-10",
                            attrs: {
                              "data-cy":
                                "base-invoice-create-duplicate-submit-btn",
                              id: "Testing__DuplicateInvoiceSubmitBtn"
                            },
                            on: { click: _vm.submitInvoice }
                          },
                          [
                            _vm._v(
                              "\n            Yes, Submit Possible Duplicate\n          "
                            )
                          ]
                        ),
                        _c(
                          "base-button",
                          {
                            staticClass:
                              "BaseInvoiceCreate__duplicate-modal-btn bg-light fc-white",
                            attrs: {
                              "data-cy":
                                "base-invoice-create-duplicate-cancel-btn"
                            },
                            on: {
                              click: function($event) {
                                _vm.possibleDuplicate = false
                              }
                            }
                          },
                          [_vm._v("\n            No\n          ")]
                        )
                      ],
                      1
                    ),
                    _c("label", { staticClass: "fc-light" }, [
                      _vm._v(
                        "\n          Selecting ‘Yes, Submit Possible Duplicate' will create an invoice that is flagged as a\n          possible duplicate.\n        "
                      )
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }